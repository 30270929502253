import React  from 'react';
import { Container, Navbar, NavbarBrand, Nav, NavItem, NavLink, NavDropdown} from 'react-bootstrap';
import "./MenuBar.css";

export function MenuBar(props) {
    
    return (
        <div>
            <Navbar bg="light">
                <Container>
                    <NavbarBrand id="brand-text" href="/">
                        <span id="brand-pop">Pop<span id="brand-si">si<span id="brand-code">code</span></span></span>
                    </NavbarBrand>
                </Container>
            </Navbar>
            <Navbar bg="light" expand="lg">
                <Container>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <NavItem>
                                <NavLink active href="/" >Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active href="/" >Post</NavLink>
                            </NavItem>
                            <NavDropdown title="Project" id="basic-nav-dropdown">
                                <NavLink  active href="/projects/jamming">Jamming</NavLink>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )

}
import React from 'react';
import SearchBar from "../SearchBar/SearchBar"
import SearchResults from '../SearchResults/SearchResults';
import Playlist from '../Playlist/Playlist';
import Spotify from '../../util/Spotify';
import './Jamming.css';

class Jamming extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			searchResults:[],
			playlistName: "New Playlist",
			playlistTracks: []
		};

		this.addTrack = this.addTrack.bind(this);
		this.removeTrack = this.removeTrack.bind(this);
		this.updatePlaylistName = this.updatePlaylistName.bind(this);
		this.savePlaylist = this.savePlaylist.bind(this);
		this.search = this.search.bind(this);
	}

	addTrack(track) {
		let tracks = this.state.playlistTracks;
		if (tracks.find(savedTrack => savedTrack.id === track.id)) {
			return;
		}

		tracks.push(track);
		this.setState({ playlistTracks: tracks });
	}

	removeTrack(track) {

		let tracks = this.state.playlistTracks;
		tracks = tracks.filter(t => t.id !== track.id);

		this.setState({ playlistTracks: tracks });

	}

	updatePlaylistName(name) {
		this.setState({playlistName : name});
		
	}

	savePlaylist() {
		const trackURIs = this.state.playlistTracks.map(track => track.uri)
		Spotify.savePlaylist(this.state.playlistName, trackURIs).then(() => {
			this.setState({
				playlistName: "New Playlist",
				playlistTracks: []
			})
		})
	}

	search(term) {
		Spotify.search(term).then(searchResults => {
			this.setState({searchResults : searchResults})
		});
		

	}

	render() {
		return (
			<div>
				<h1 className="h1-highlight" style={{marginBottom:0}}>Jammming</h1>
				<div className="App">
					<SearchBar onSearch={this.search}/>
					<div className="App-playlist">
						
						<SearchResults 
							onAdd={this.addTrack} 
							searchResults={this.state.searchResults}/>
							
						<Playlist 
							playlistName={this.state.playlistName} 
							tracks={this.state.playlistTracks}
							onRemove={this.removeTrack}
							isRemoval={true}
							onNameChange={this.updatePlaylistName}
							onSave={this.savePlaylist}/>
					</div>
				</div>
			</div>
		);
	}
}

export default Jamming;

const client_id = "be73394930674320b035a63cf81c2e2b";
// var redirect_uri = 'https://jamming-popsicode.surge.sh';
//var redirect_uri = 'https://popsicode.com/projects/jamming';
var redirect_uri = 'http://localhost:3000/projects/jamming';
var scope = 'playlist-modify-public';

var url = 'https://accounts.spotify.com/authorize';
url += '?response_type=token';
url += '&client_id=' + encodeURIComponent(client_id);
url += '&scope=' + encodeURIComponent(scope);
url += '&redirect_uri=' + encodeURIComponent(redirect_uri);



let expiration;

const Spotify = {

    getAccessToken() {

        let token = localStorage.getItem("spotify_token");

        if (token) {
            return token;
        } 

        let t = window.location.href.match(/access_token=([^&]*)/);
        let exp = window.location.href.match(/expires_in=([^&]*)/);

        if (t && exp) {

            token = t[1];
            localStorage.setItem("spotify_token", token)

            expiration = Number(exp[1]);
            
            window.setTimeout(() => localStorage.setItem("spotify_token", ''), expiration * 1000);
            window.history.pushState('Access Token', null, '/projects/jamming');

            return token;

        } else {
            window.location = url;
            
        }
            
    },

    search(term) {

        const accessToken = Spotify.getAccessToken();
        const endpoint = "https://api.spotify.com/v1/search?type=track&q=" + term;
        
        return fetch(endpoint, {
                headers: {Authorization: 'Bearer ' + accessToken}
            }).then(response => {
                return response.json();
            }).then(jsonResponse => {
                if (!jsonResponse.tracks){
                    return []
                }

                return jsonResponse.tracks.items.map(track => ({
                    id: track.id,
                    name: track.name,
                    artist: track.artists[0].name,
                    album: track.album.name,
                    uri: track.uri
                }));
            })
            
    },

    savePlaylist(newPlaylistName, uriArray) {
        
        if (!newPlaylistName || !uriArray) {
            return;
        }

        const accessToken = Spotify.getAccessToken();
        const header = {Authorization: 'Bearer ' + accessToken};
        let userID;
        
        return fetch("https://api.spotify.com/v1/me", {
                headers: header
            }).then(response => {
                return response.json();
            }).then(jsonResponse =>{
                
                userID = jsonResponse.id;

                return fetch(`https://api.spotify.com/v1/users/${userID}/playlists`, {
                    headers: header,
                    method: 'POST',
                    body: JSON.stringify({
                        name: newPlaylistName,
                    })
                }).then(response => {
                    return response.json();
                }).then(jsonResponse =>{

                    const playlistID = jsonResponse.id
                    
                    return fetch(`https://api.spotify.com/v1/users/${userID}/playlists/${playlistID}/tracks`,{
                        headers: header,
                        method: 'POST',
                        body: JSON.stringify({uris: uriArray})
                    })
            })
        })
            

    }


}


export default Spotify

import React from 'react';
import { MenuBar } from '../../Components/MenuBar';
import {Layout,  Image,Avatar, Row, Col, Card } from "antd";
const { Content } = Layout;
const { Meta } = Card;

const phtext = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor ";

export function Home(props){

    return(
        <Layout>
            <MenuBar />
            <Layout style={{height:"100vw", backgroundImage:"url(./background.png)", backgroundRepeat: false}}>
                <Content calssName="site-card-wrapper" style={{ width:"70vw", margin: "auto" , marginTop: "1%"}}>
                    <Row gutter={16}>
                        <Col span={16}>
                            <Card style={{marginTop:'0%'}}>
                                <Card.Grid style={{width: '40%', padding:0, margin:0}} hoverable={false}>
                                    <Card cover={<Image src='./post1.png' preview={false} />} />
                                </Card.Grid>
                                <Card.Grid style={{width: '60%'}} hoverable={false}>
                                    <Meta 
                                        title="Post"
                                        description={phtext}
                                    />
                                </Card.Grid>
                            </Card>
                            <Card style={{marginTop:'5%'}}>
                                <Card.Grid style={{width: '40%', padding:0, margin:0}} hoverable={false}>
                                    <Card cover={<Image src='./post2.png' preview={false} />} />
                                </Card.Grid>
                                <Card.Grid style={{width: '60%'}}hoverable={false}>
                                    <Meta 
                                        title="Post"
                                        description={phtext}
                                    />
                                </Card.Grid>
                            </Card>
                            
                        </Col>
                        <Col span={8}>
                            <Card style={{ textAlign: "center"}}>
                                <h2>About me</h2>
                                <Avatar src={<Image src='./avatar.jpg' />} size={100} style={{ backgroundColor: '#f56a00' }}>C</Avatar>
                                <p>{phtext}</p>
                            </Card>
                            
                        </Col>
                    </Row>
                </Content>
                
            </Layout>
        </Layout>
    )

}
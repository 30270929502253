import React from 'react';
import Tracklist from '../Tracklist/Tracklist';
import "./Playlist.css"

class Playlist extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name:""
        };

        this.handleNameChange = this.handleNameChange.bind(this);
    }

    handleNameChange(e) {
        this.setState({name: e.target.name});
    }

    render() {
        return (
            <div className="Playlist">
                <input defaultValue={"New Playlist"} name={this.state.name} onChange={this.handleNameChange}/>
                <Tracklist tracks={this.props.tracks} isRemoval={true} onRemove={this.props.onRemove}/>
                <button className="Playlist-save" onClick={this.props.onSave}>SAVE TO SPOTIFY</button>
            </div>
        )
    }
}

export default Playlist;